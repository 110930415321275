import React from 'react'
import ReactDOM from 'react-dom/client'
import Mixpanel from './lib/mixpanel'
import App from './app'
import './styles/globals.less'
import './i18n/config'
import { NextUIProvider } from '@nextui-org/react'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'

// Initialize all tracking services once at app startup
Mixpanel.initialize();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d002d8a7586fff307b564d40a5a7c8fd@o4508318930829312.ingest.de.sentry.io/4508318933319760',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/app\.strom\.ai\/api/, /^https:\/\/dev\.strom\.ai\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const exception = hint.originalException as AxiosError
      if (exception && exception.response && exception.response.status === 401) {
        return null
      }
      return event
    },
  })
}

// Define Inter as default font (from Tailwind CSS)
<link rel="stylesheet" href="https://rsms.me/inter/inter.css" type="text/css" />

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <NextUIProvider>
    <main className="h-screen min-h-screen light">
      <App />
    </main>
    {/* Global styles to remove blue outline from sidebar ('ul > a' elements)*/}
    <style jsx="true" global="true">{`
      ul a:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    `}</style>
  </NextUIProvider>,
)
