import mixpanel from 'mixpanel-browser'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import TagManager from 'react-gtm-module'
import { v4 as uuidv4 } from 'uuid';

const GA_TRACKING_ID = 'G-9QV2S2FP56'

let trackingDebugStatus = false
if (process.env.NODE_ENV === 'development') {
  trackingDebugStatus = true
}

// Flag to ensure initialization runs only once
let initialized = false

// Initialize Google Analytics
const initializeGA = () => {
  TagManager.initialize({ gtmId: GA_TRACKING_ID }) // Uses Google Tag Manager
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  window.gtag = window.gtag || gtag // ✅ Ensure `gtag` is defined
  window.gtag('js', new Date())
  window.gtag('config', GA_TRACKING_ID, { debug_mode: false })
  console.log('✅ Google Analytics initialized successfully (Debug Mode Active)') // Debugging Log
}

// Utility function to get cookies
function getCookie(name: string): string | null {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
      const [key, value] = cookie.split("=");
      if (key === name) return decodeURIComponent(value);
  }
  return null;
}

// Utility function to set cookies
function setCookie(name: string, value: string, days: number) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/; domain=.powerdocs.so; Secure; SameSite=None`;
}

// Function to get tracking data with fallback from cookies
function getTrackingData(param: string): string | null {
  return localStorage.getItem(param) || getCookie(param);
}

// Initialize all tracking services
const initialize = () => {
  if (initialized) return
  initialized = true

  const params = new URLSearchParams(window.location.search)
  let mpid = params.get('mpid') || getTrackingData('mpid'); // Uses cookie fallback
  
  if (trackingDebugStatus && mpid) {
    console.log(`Found mpid in URL/localStorage: ${mpid}`)
  }

  // Initialize Mixpanel
  mixpanel.init('7f0a04a424de8ab3c9094d0ce39b7dc2', {
    persistence: 'localStorage',
    cookie_domain: '.powerdocs.so',
    track_pageview: 'full-url',
    debug: trackingDebugStatus
  })

  window.amplitude = amplitude; // Expose amplitude globally

  // Initialize Amplitude
  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.init('c31b439bfc9b47f46d3accd2defeb780', {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
  amplitude.add(sessionReplayTracking);

  initializeGA() // Initialize Google Analytics

  // Capture UTM & Ad Tracking Data on page load
  Mixpanel.trackUTMAndSetProfile();

  let distinctId = mixpanel.get_distinct_id() // Get Mixpanel ID
  console.log(`Current Mixpanel Distinct ID after init: ${distinctId}`)

  if (mpid) {
    console.log(`✅ Found mpid from URL/localStorage: ${mpid}`);
    localStorage.setItem("mpid", mpid) // Store `mpid` for session persistence
    setCookie("mpid", mpid, 30); // Also save mpid in cookies

    let sanitizedMpid = mpid.startsWith("$device:") ? mpid.replace("$device:", "") : mpid;
    
    if (trackingDebugStatus) console.log(`Initializing cross-domain tracking with mpid: ${mpid}`);
    
    let currentDistinctId = mixpanel.get_distinct_id();
    if (trackingDebugStatus) {
        console.log(`Before identifying, current Mixpanel ID: ${currentDistinctId}`);
    }

    // 🚨 Do NOT use alias() here! Instead, directly identify the user with mpid
    mixpanel.identify(sanitizedMpid);  // Correct usage of identify
    mixpanel.people.set({ $distinct_id: sanitizedMpid });

    // Update our local reference to the ID
    distinctId = sanitizedMpid;

    // Remove the parameter from URL for cleanliness
    params.delete("mpid");
    const newUrl = window.location.pathname + (params.toString() ? `?${params.toString()}` : "");
    window.history.replaceState({}, "", newUrl);

    // Verify identification worked
    if (trackingDebugStatus) {
        console.log(`After identifying, Mixpanel ID: ${mixpanel.get_distinct_id()}`);
        console.log(`Should match sanitized mpid: ${sanitizedMpid}`);
    }
  } else {
    let existingDistinctId = mixpanel.get_distinct_id(); // Get current Mixpanel ID

    if (!existingDistinctId || existingDistinctId.startsWith("$device:")) {
        // Generate a new anonymous ID only if none exists
        distinctId = `anon-${uuidv4()}`; // Generates proper GUID format
        mixpanel.identify(distinctId);
    } else {
        distinctId = existingDistinctId; // Preserve the current distinct ID
    }

    if (trackingDebugStatus) {
        console.log(`Identified with existing/generated ID: ${distinctId}`);
    }

    console.log(`✅ Final Identified Mixpanel ID: ${mixpanel.get_distinct_id()}`);
  }
}

// Unified Tracking Function
const trackEvent = (eventName: string, info: any = {}) => {
  const userString = localStorage.getItem('user')
  if (userString) {
    try {
      const user = JSON.parse(userString)
      info = { ...info, user_id: user.uuid }
    } catch (err) {
      if (trackingDebugStatus) console.error('Error parsing user data from local storage', err)
    }
  }

  if (trackingDebugStatus) {
    console.log(`Sending event to Mixpanel: "${eventName}"`, info)
    console.log(`Sending event to Amplitude: "${eventName}"`, info)
    console.log(`Sending event to Google Analytics: "${eventName}"`, info)
  }

  mixpanel.track(eventName, info)
  amplitude.track(eventName, info)

  // Send event to Google Analytics (gtag)
  if (window.gtag) {
    console.log(`📡 Sending event to GA4: ${eventName}`, info) // ✅ Debug Log
    window.gtag('event', eventName, info)
  } else {
    console.error('❌ GA4 (gtag) not found, event not sent:', eventName)
  }
}

const Mixpanel = {
  initialize,

  getDistinctId: () => mixpanel.get_distinct_id(),

  appendDistinctId: (url: string): string => {
    try {
      const urlObj = new URL(url)
      urlObj.searchParams.set('mpid', mixpanel.get_distinct_id())
      return urlObj.toString()
    } catch (error) {
      if (trackingDebugStatus) console.error('Error appending distinct_id to URL:', error)
      return url
    }
  },

  login: (userId: number) => {
    if (trackingDebugStatus) console.log(`track login ${userId}`)
    mixpanel.identify(`${userId}`)
    amplitude.setUserId(userId.toString())

    if (window.gtag) {
      window.gtag('set', { user_id: userId.toString() })
    }
  },

  signup: (data: any) => {
    if (trackingDebugStatus) console.log(`track signup`, data);
    
    const userId = data.user_id?.toString() || '';

    if (userId) {
        mixpanel.alias(userId); // Alias only at signup
        mixpanel.identify(userId); // Ensure future tracking uses this ID
    }

    // Fetch stored UTM & Ad data
    const trackingParams = [
      "utm_source", "utm_medium", "utm_campaign", "utm_id", 
      "utm_source_platform", "utm_term", "utm_content",
      "gclid", "gbraid", "fbclid", "gad_source"
    ]

    const storedTrackingData: { [key: string]: string } = {}

    trackingParams.forEach((param) => {
      const value = getTrackingData(param); // Uses cookie fallback
      if (value) {
        storedTrackingData[param] = value
      }
    })

    // Merge tracking data into user profile data
    const userProfileData = { ...data, ...storedTrackingData }
    console.log("tracked ad data::::: " + userProfileData);

    mixpanel.people.set(userProfileData);
    amplitude.setUserId(userId);

    const identifyObj = new amplitude.Identify();
    Object.entries(userProfileData).forEach(([key, value]) => identifyObj.set(key, value));
    amplitude.identify(identifyObj);

    // ✅ Ensure GA4 has fully loaded before sending the event
    if (typeof window.gtag === 'function') {
        window.gtag('event', 'signup', { user_id: userId, ...storedTrackingData });
    } else {
        console.warn('⚠️ GA4 not initialized yet, delaying signup event...');
        setTimeout(() => {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'signup', { user_id: userId });
            } else {
                console.error('❌ GA4 failed to initialize, signup event not sent.');
            }
        }, 1000); // Delay 1 second before retrying
    }
  },

  logout: () => {
    if (trackingDebugStatus) console.log(`track logout`)
    mixpanel.reset()
    amplitude.setUserId(null)
    const identifyObj = new amplitude.Identify()
    identifyObj.clearAll()
    amplitude.identify(identifyObj)

    if (window.gtag) {
      window.gtag('event', 'logout')
    }
  },

  track: (prop: string, info: any = {}) => {
    trackEvent(prop, info)
  },

  trackUTMAndSetProfile() {
    const trackingParams = [
        "utm_source", "utm_medium", "utm_campaign", "utm_id", 
        "utm_source_platform", "utm_term", "utm_content",
        "gclid", "gbraid", "fbclid", "gad_source"
    ];

    const trackingData: { [key: string]: string } = {};

    trackingParams.forEach((param) => {
        const value = getTrackingData(param); // ✅ Uses cookie fallback
        if (value) {
            trackingData[param] = value;
        }
    });

    if (Object.keys(trackingData).length > 0) {
        mixpanel.track("Tracking Data Captured", trackingData);
        mixpanel.people.set(trackingData);
        amplitude.track("Tracking Data Captured", trackingData);

        if (window.gtag) {
            window.gtag("event", "Tracking Data Captured", trackingData);
        }
    }
  }
}

export default Mixpanel
